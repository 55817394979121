import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[auto-focus]',
})
export class AutoFocusCmp {
  private onceFocused = false;

  constructor(private el:ElementRef) {
    if (this.el && this.el.nativeElement) {
      this.el.nativeElement.setAttribute('autofocus', true);
    }
  }

  ngAfterViewChecked() {
    try {
      if (!this.onceFocused && this.el && this.el.nativeElement) {
        let i = this.el.nativeElement;
        setTimeout(()=> {
          if (this.onceFocused) {
            return;
          }
          let ex = i.getAttribute('tabindex');
          i.setAttribute('tabindex', '-1');
          i.focus();
          this.onceFocused = true;
          if (ex === null || (typeof ex === 'undefined')) {
            i.removeAttribute('tabindex');
          } else {
            i.setAttribute('tabindex', ex);
          }
        }, 0);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
