import {Pipe} from '@angular/core';

@Pipe({
  name: 'arr_join'
})
export class ArrayJoinNotEmptyPipe {
  transform(list, separator?:string, prefix?:string):any {
    if (!list || !list.length) {
      return '';
    }
    if (!separator) {
      separator = ', ';
    }
    let newList = [];
    list.forEach((item)=> {
      if (item && item !== "" && item.length > 0) {
        newList.push(item);
      }
    });
    let str = newList.join(separator);
    if (str && prefix) {
      str = prefix + str;
    }
    return str;
  }
}
